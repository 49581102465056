import React from "react"
import { asCurrency } from "../../services/Utils"
import { PurchaseOrderItemService } from "../../services/PurchaseOrderItemService"
import { QuantitySelect } from "../seller/QuantitySelect"
import { ProductUnitSelect } from "../seller/ProductUnitSelect"
import { CatalogFetcher } from "../../services/CatalogFetcher"
import { toast } from "react-toastify"

export class PurchaseOrderItemNew extends React.Component {
  constructor(props) {
    super(props)

    this.quantitySelect = React.createRef()
    this.addButton = React.createRef()
    this.onCreate = this.onCreate.bind(this)
    this.onChangeQuantity = this.onChangeQuantity.bind(this)
    this.onProductUnitSelected = this.onProductUnitSelected.bind(this)
    this.notifyParentOfSubtotalChange = this.notifyParentOfSubtotalChange.bind(this)
    this.state = {
      purchase_order_item: { quantity: 1, buyer_order_id: this.props.purchase_order.id }
    }
  }

  onCreate() {
    let purchase_order_item = this.state.purchase_order_item
    purchase_order_item.src = 'sales_order_edit'
    PurchaseOrderItemService.create(purchase_order_item).then((new_purchase_order_item) => {
      this.props.createdPurchaseOrderItem(new_purchase_order_item)
      this.setState({
        purchase_order_item: { quantity: 1, buyer_order_id: this.props.purchase_order.id },
        product_unit: null
      }, () => { this.quantitySelect.current.refocus() })
    },
    (error) => { toast(error.message, { className: "bg-danger text-white" }) }
  )
  }

  notifyParentOfSubtotalChange() {
    this.props.onSubtotalUpdate(this.calculateSubtotal())
  }

  onChangeQuantity(newValue) {
    let purchase_order_item = this.state.purchase_order_item
    purchase_order_item.quantity = newValue
    this.setState({ purchase_order_item: purchase_order_item }, this.notifyParentOfSubtotalChange)
  }

  onProductUnitSelected(product_unit) {
    if (!product_unit) { return }

    let purchase_order_item = this.state.purchase_order_item
    purchase_order_item.product_unit_id = (product_unit || {}).id
    if (product_unit.quantity_minimum > purchase_order_item.quantity) {
      purchase_order_item.quantity = product_unit.quantity_minimum
    }
    this.setState({ product_unit: product_unit, purchase_order_item: purchase_order_item },
    () => { this.notifyParentOfSubtotalChange; this.addButton.current.focus(); })
  }

  renderQuantity() {
    const product_unit = this.state.product_unit || {}
    return (
      <div>
        <QuantitySelect
          ref={ this.quantitySelect }
          value={ this.state.purchase_order_item.quantity }
          minimum={ product_unit.quantity_minimum || 1 }
          maximum={ product_unit.quantity_maximum }
          onChange={ this.onChangeQuantity } />
      </div>
    )
  }

  renderUnitPrice() {
    const product_unit = this.state.product_unit
    if (!product_unit) { return }
    return (
      <span>{ asCurrency(product_unit.price) }</span>
    )
  }

  calculateSubtotal() {
    const product_unit = this.state.product_unit
    if (!product_unit) { return 0 }
    return (this.state.purchase_order_item.quantity * product_unit.price)
  }

  renderSubtotal() {
    const product_unit = this.state.product_unit
    if (!product_unit) { return }
    return (
      <span>{ asCurrency("" + this.calculateSubtotal()) }</span>
    )
  }

  render() {
    const product_unit = this.state.product_unit
    let rowClass = "order-row no-gutters d-flex py-2 align-items-end"
    let buttonClass
    if (!product_unit) {
      buttonClass = "btn-primary disabled"
    } else {
      rowClass += " bg-warning"
      buttonClass = "btn-primary"
    }

    return (
      <div id='new-item-row' className={rowClass}>
        <div className='toggle-more-column'>
          <button disabled='disabled' className="btn btn-white btn-sm text-white"><span className="fa fa-times"></span></button>
        </div>
        <div className='quantity-column'>
          { this.renderQuantity() }
        </div>
        <div className='w-100'>
          <div>
            <ProductUnitSelect
                published={true}
                seller_id={ this.props.purchase_order.seller_id }
                fetcher={ CatalogFetcher.queryAvailable }
                value={ this.state.product_unit }
                onProductUnitSelected={ this.onProductUnitSelected }
            />
          </div>
        </div>
        <div className="pl-2">
          <button
            className={`btn ${buttonClass} text-nowrap`}
            onClick={ this.onCreate }
            disabled={ !product_unit || product_unit.unavailable_type == 'indefinite' }
            ref={ this.addButton } >
            <span className='d-none d-md-inline'>{ product_unit ? 'Add Item To Order' : 'Search to Add Item'}</span>
            <span className='d-inline d-md-none'><span className='fa fa-plus'></span></span>
          </button>
        </div>
      </div>
    )
  }
}
export default PurchaseOrderItemNew