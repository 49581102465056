import { Controller } from '@hotwired/stimulus';
import "bootstrap";  // Ensure Bootstrap is imported

export default class extends Controller {
  connect() {
    var element = this.element
    $(element).popover();
  }

  disconnect() {
    // Clean up popovers when the controller is disconnected
    $(this.element).popover('dispose');
  }
}