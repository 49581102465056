document.addEventListener('turbo:load', () => {
  // add multiple select / deselect functionality
  const checkAlls = document.querySelectorAll('.selectall');
  checkAlls.forEach((checkAll) => {
    checkAll.addEventListener('change', () => {
      const checkboxes = checkAll.closest('form').querySelectorAll('.case');
      checkboxes.forEach(checkbox => checkbox.checked = checkAll.checked);
      checkboxes.forEach(checkbox => checkbox.dispatchEvent(new Event('change')));
    })
  });

  $(".selectall-new").on('click', function() {
    var checkAll = $(this).prop('checked');
    if (checkAll) {
      $(this).closest(".selectall-container").find(".case").prop("checked", true);
    } else {
      $(this).closest(".selectall-container").find(".case").prop("checked", false);
    }
  });

  $('button[data-collect="true"]').on('click', function() {
    var editMultipleForm = $(this).closest("form")
    editMultipleForm[0].querySelectorAll('.selectall-container input[name="ids[]"]').forEach(
      function(existingInput) { existingInput.remove() }
    )
    $(this).closest(".selectall-container").find('.case:checked').each(
      function() {
        var value = $(this).val()
        var newInput = '<input type="hidden" name="ids[]" value="' + value + '">'
        editMultipleForm.prepend(newInput)
      }
    )
  });

  $('button[data-toggle="reroute-multiple-modal"]').on('click', function(event) {
    var contentSection = $('#update-multiple-routes .content')

    contentSection.html('')
    $(this).data().orderIds.forEach(
      function(id) {
        var newInput = '<input type="hidden" name="order_ids[]" value="' + id + '">'
        contentSection.prepend(newInput)
      }
    )
    $(this).data().addresses.forEach(
      function(address) {
        var newInput = '<input type="hidden" name="addresses[]" value="' + address + '">'
        contentSection.prepend(newInput)
      }
    )
    text = '<div class="h3">' + $(this).data().orderIds.length + ' orders</div>'
    contentSection.prepend(text)
    $('#reroute-multiple-modal').modal()
  });

  // if all checkbox are selected, check the selectall checkbox and vice versa
  $(".case").click(function(){
    if ($(this).closest("form").find(".case").length == $(this).closest("form").find(".case:checked").length) {
        $(this).closest("form").find(".selectall").prop("checked", true);
    } else {
        $(this).closest("form").find(".selectall").prop("checked", false);
    }
  });
})
