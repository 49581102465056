import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.clone.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("click", this.clone.bind(this));
  }

  clone() {
    const targetForm = this.element.closest('form');
    const cloneFrom = document.querySelector(this.element.dataset.clonerCloneFrom);
    if (targetForm && cloneFrom) {
      const clonedElement = cloneFrom.cloneNode(true);
      targetForm.append(clonedElement);
    }
  }
}