import "core-js/stable";
import 'regenerator-runtime/runtime'

import DataTable from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css';
$.fn.DataTable = DataTable;

require("../src/datatables_init.js")

window.$ = window.jQuery = require('jquery');

require("@rails/ujs").start()
global.Rails = Rails;

import 'bootstrap'
require("../src/bootstrap_init.js")

import Sortable from 'sortablejs'
global.Sortable = Sortable;

import "@hotwired/turbo-rails"

import LocalTime from "local-time"
LocalTime.start()

import "chartkick/chart.js"
import { Chart } from "chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels)
Chart.defaults.set('plugins.datalabels', {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  font: { size: 0 }
});

require("../app/application.js")
require("../app/stripe_seller_add_card.js")
require("../app/stripe_seller_add_bank_account.js")
require("../app/stripe_buyer_setup_intent.js")

import "../controllers"
require("../css/application.scss")

import '@selectize/selectize/dist/js/selectize.min.js'
import '@selectize/selectize/dist/css/selectize.bootstrap4.css'

require("../src/chosen_init.js")

import 'timepicker/jquery.timepicker'
import 'timepicker/jquery.timepicker.css'

import flatpickr from "flatpickr"
import 'flatpickr/dist/flatpickr.min.css'
import mapboxgl from "mapbox-gl"
global.mapboxgl = mapboxgl
import 'mapbox-gl/dist/mapbox-gl.css';

require("jquery-ui/ui/widgets/sortable")
require("jquery-ui-touch-punch/jquery.ui.touch-punch.min.js")

require("../src/ahoy.js")
require('jquery-sparkline/jquery.sparkline.min.js')
require("../src/timeout_poll.js")
require("../src/date_range_picker.js")
require("../src/flatpickr.js")
require("../src/mapbox.js")
require("../src/market_sheet.js")
require("../src/deposits.js")
require("../src/selectall.js")
require("../src/sorting.js")
require("../src/table_to_csv.js")

// Support component names relative to this directory:
var componentRequireContext = require.context("../components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);

