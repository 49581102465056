import React from "react"
import { CartItem } from "./CartItem"
import _ from "lodash"
import { asCurrency } from "../../services/Utils"

export class Cart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }
  }

  toggle() {
    this.setState({
      visible: !this.state.visible
    })
  }

  seeOrder() {
    let path;
    if (this.props.seller_browsing) {
      path = "/admin/sales_orders/" + this.props.buyer_order.id + "/edit"
    } else {
      path = "/admin/purchase_orders/" + this.props.buyer_order.id + "/checkout"
    }
    window.location = path
  }

  removeItem(order_item_id) {
    if (confirm("Are you sure you want to remove this product from your cart?")) {
      this.props.removeOrderItem(order_item_id)
    }
  }

  updateOrderItemQuantity(buyer_order_item_id, quantity) {
    this.props.updateOrderItemQuantity(buyer_order_item_id, quantity)
  }

  renderProducts() {
    const buyer_order_items = this.props.buyer_order.buyer_order_items

    if (buyer_order_items.length > 0) {
      return _.map(buyer_order_items, (buyer_order_item) => {
        return (
          <CartItem
            key={buyer_order_item.id}
            buyer_order_item={buyer_order_item}
            remove={this.removeItem.bind(this)}
            showProducer={this.props.showProducer}
            editQuantity={this.updateOrderItemQuantity.bind(this)}
          />
        )
      })
    } else {
      return (
        <div className="mt-4 text-center">
          <span>No items in this order</span>
        </div>
      )
    }
  }

  render() {
    if (!this.props.buyer_order ) { return <div></div> }

    const buyer_order = this.props.buyer_order
    const orderTotal = _.sumBy(buyer_order.buyer_order_items, (x) => { return Number(x.subtotal) } )
    const itemCount = _.sumBy(this.props.buyer_order.buyer_order_items, (item) => { return Number(item.quantity) } )

    const body = (
      <div className="popout-shopping-cart-container text-left">
        <div className="p-3 popout-shopping-cart-header">
          <div className='d-flex justify-content-between'>
            <div>
              {buyer_order.name || "Order #" + buyer_order.id}
            </div>
            <div className="float-right">
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={this.toggle.bind(this)}
              >
                <i className="fa fa-times" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div>
              <button
                className="btn btn-success"
                onClick={this.seeOrder.bind(this)}
              >
                <span className="fa fa-shopping-cart mr-1"></span>
                Checkout
              </button>
            </div>
            <div className="align-self-center">
              { asCurrency(orderTotal) }
            </div>
          </div>
        </div>
        <div className="popout-shopping-cart-products">
          { this.renderProducts() }
        </div>
      </div>
    )

    return (
      <div className='ml-2'>
        <button
          id="shopping-cart-toggle"
          className="drop-shadow-hover btn btn-success"
          onClick={this.toggle.bind(this)}
        >
          <i className="fa fa-shopping-cart" aria-hidden="true" />
          <div>{ itemCount }</div>
        </button>
        { this.state.visible ? body : null }
      </div>
    )
  }
}
