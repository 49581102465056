import { Controller } from '@hotwired/stimulus';
import { ajax } from '@rails/ujs'; // Ensure Rails UJS is installed and imported

export default class extends Controller {
  connect() {
    $(this.element).popover({
      delay: { show: 300, hide: 50 },
      trigger: 'manual',
      html: true
    });

    this.hoverState = null;
    this.timeout = null;

    this.element.addEventListener('mouseenter', this.showPopover.bind(this));
    this.element.addEventListener('mouseleave', this.hidePopover.bind(this));
    this.element.addEventListener('touchstart', this.showPopover.bind(this));
    this.element.addEventListener('touchleave', this.hidePopover.bind(this));

    // Bind Bootstrap's popover show event
    $(this.element).on('show.bs.popover', this.fetchPopoverContent.bind(this));
  }

  showPopover() {
    clearTimeout(this.timeout);
    this.hoverState = 'in';

    this.timeout = setTimeout(() => {
      if (this.hoverState === 'in') {
        $(this.element).popover('show');

        const popoverElement = document.querySelector('.popover');
        if (popoverElement) {
          popoverElement.addEventListener('mouseenter', this.cancelHidePopover.bind(this));
          popoverElement.addEventListener('mouseleave', this.startHidePopover.bind(this));
        }
      }
    }, 300); // Match the show delay
  }

  hidePopover() {
    clearTimeout(this.timeout);
    this.hoverState = 'out';

    this.timeout = setTimeout(() => {
      if (this.hoverState === 'out') {
        $(this.element).popover('hide');
      }
    }, 50); // Match the hide delay
  }

  cancelHidePopover() {
    clearTimeout(this.timeout);
  }

  startHidePopover() {
    this.hidePopover();
  }

  fetchPopoverContent() {
    const id = this.element.dataset.id;

    ajax({
      url: `/admin/product_units/${id}/order_items`,
      type: 'GET',
      dataType: 'script'
    });
  }

  disconnect() {
    $(this.element).popover('dispose');
    this.element.removeEventListener('mouseenter', this.showPopover.bind(this));
    this.element.removeEventListener('mouseleave', this.hidePopover.bind(this));
    $(this.element).off('show.bs.popover');
  }
}