import React from "react"
import { ProductCatalog } from "./ProductCatalog"
import { CatalogFetcher } from "../../services/CatalogFetcher"
import { Cart } from "./Cart"
import { PurchaseOrderService } from "../../services/PurchaseOrderService"
import { PurchaseOrderItemService } from "../../services/PurchaseOrderItemService"
import { toast } from "react-toastify"

export class OrderCatalog extends React.Component {
  constructor(props) {
    super(props)
    this.createOrUpdateItem = this.createOrUpdateItem.bind(this);
    this.createOrderAndItem = this.createOrderAndItem.bind(this)
    this.updateRequestedOn = this.updateRequestedOn.bind(this)
    this.removeOrderItem = this.removeOrderItem.bind(this)
    this.updateOrderItemQuantity = this.updateOrderItemQuantity.bind(this)
    this.addItem = this.addItem.bind(this)
    this.state = {
      buyer_order: props.buyer_order || { buyer_order_items: [], requested_on: props.requested_on }
    }
  }

  createOrderAndItem(buyer_order_item_params, product_name, src) {
    const requested_on = this.state.buyer_order.requested_on
    PurchaseOrderService.create(this.props.buyer_id, this.props.seller.id, requested_on).then(
      (buyer_order) => {
        this.setState({ buyer_order: buyer_order })
        this.createOrUpdateItem(buyer_order.id, buyer_order_item_params, product_name, src)
        const newUrl = "/admin/purchase_orders/" + buyer_order.id + "/browse"
        window.history.replaceState({}, "Catalog", newUrl)
      }
    )
  }

  createOrUpdateItem(buyer_order_id, buyer_order_item_params, product_name, src) {
    buyer_order_item_params.buyer_order_id = buyer_order_id
    buyer_order_item_params.src = src
    PurchaseOrderItemService.create(buyer_order_item_params).then(
      (buyer_order_item) => {
        let buyer_order = this.state.buyer_order
        const index = _.findIndex(buyer_order.buyer_order_items, { id: buyer_order_item.id })
        if ( index === -1 ) {
          buyer_order.buyer_order_items.push(buyer_order_item)
        } else {
          buyer_order.buyer_order_items[index] = buyer_order_item
        }
        this.setState({buyer_order: buyer_order})
        toast('Added to cart: ' + product_name, { className: "bg-success text-white"})
      },
      (error) => { toast(error.message, { className: "bg-danger text-white" }) }
    )
  }

  addItem(buyer_order_item_params, name, src) {
    if (!this.state.buyer_order.id) {
      this.createOrderAndItem(buyer_order_item_params, name, src)
    } else {
      this.createOrUpdateItem(this.state.buyer_order.id, buyer_order_item_params, name, src)
    }
  }

  updateRequestedOn(requested_on) {
    if (!this.state.buyer_order.id) {
      let buyer_order = this.state.buyer_order
      buyer_order.requested_on = requested_on
      this.setState({ buyer_order: buyer_order})
    }
    let payload = { id: this.state.buyer_order.id, requested_on: requested_on }
    PurchaseOrderService.update(payload).then(
      () => {

      }
    )
  }

  removeOrderItem(order_item_id) {
    PurchaseOrderItemService.destroy(order_item_id).then(() => {
      this.removeItemFromBuyerOrder(order_item_id)
    })
  }

  removeItemFromBuyerOrder(buyer_order_item_id) {
    let buyer_order = this.state.buyer_order
    let item_to_remove = _.find(buyer_order.buyer_order_items, { id: buyer_order_item_id })
    const new_buyer_order_items = _.without(buyer_order.buyer_order_items, item_to_remove)
    buyer_order.buyer_order_items = new_buyer_order_items
    this.setState({ buyer_order: buyer_order })
  }

  updateOrderItemQuantity(buyer_order_item_id, quantity) {
    const buyer_order_item = {
      id: buyer_order_item_id,
      quantity: quantity
    }
    PurchaseOrderItemService.update(buyer_order_item).then(
      (new_purchase_order_item) => {
        toast("Quantity updated", { className: "bg-success text-white" })
        let buyer_order = this.state.buyer_order
        let item = _.find(buyer_order.buyer_order_items, { id: buyer_order_item_id })
        item.quantity = new_purchase_order_item.quantity
        item.subtotal = new_purchase_order_item.subtotal
        this.setState({ buyer_order: buyer_order })
      },
      (error) => { toast(error.message, { className: "bg-danger text-white" }) }
    )
  }

  renderCart() {
    if (!this.props.seller.accepting_orders || !this.state.buyer_order.id) { return }
    return (
      <Cart buyer_order={this.state.buyer_order}
            removeOrderItem={this.removeOrderItem}
            updateOrderItemQuantity={this.updateOrderItemQuantity}
            seller_browsing={this.props.seller_browsing}
            showProducer={this.props.showProducer} />
    )
  }

  render() {
    const fetcher = (page, batch, params) => {
      if ( this.props.private_catalog_id ) {
        return CatalogFetcher.queryCatalog(
          this.props.seller.id,
          this.props.private_catalog_id,
          page,
          batch,
          params
        )
      } else {
        return CatalogFetcher.queryAvailable(
          this.props.seller.id,
          page,
          batch,
          params
        )
      }
    }

    return (
      <ProductCatalog
        fetcher={fetcher}
        producers={this.props.producers}
        categories={this.props.categories}
        collections={this.props.collections}
        requested_on_options={this.props.requested_on_options}
        preloaded_products={this.props.preloaded_products}
        seller={this.props.seller}
        buyer_id={this.props.buyer_id}
        requestedOn={ this.state.buyer_order.requested_on }
        addItem={this.addItem}
        updateRequestedOn={this.updateRequestedOn}
        cartEnabled={this.props.seller.accepting_orders && this.props.buyer_id}
        cart={this.renderCart()}
        showProducer={this.props.showProducer}
        showPricing={this.props.showPricing}
        startTerm={this.props.startTerm}
        selectedProducerId={this.props.selectedProducerId}
        type="buyer"
      />
    )
  }
}
export default OrderCatalog